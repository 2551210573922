<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Báo cáo sản lượng lái xe</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="openSearch()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="driverName" @md-selected="getDriverSelected" :md-options="drivers" @md-changed="getDrivers"  @md-opened="getDrivers">
                                    <label>Lái xe</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openDriver()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm lái xe</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div style="overflow: auto; width: 100%;">
                        <table class="data-table" style="width: 100% !important;">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="width:50px;">#</th>
                                    <th rowspan="2" style="width:200px;">Tên lái xe</th>
                                    <th rowspan="2" style="width:130px;">Số điện thoại</th>
                                    <th rowspan="2" style="width:100px;">Số chuyến</th>
                                    <th colspan="3" style="width:360px; background-color: #88D66C;">Tổng hợp</th>
                                    <th colspan="5" style="width:360px; background-color: #FFD18E;">Chi phí</th>
                                    <th colspan="2" style="width:240px; background-color: #9CDBA6;">Doanh thu</th>
                                    <th rowspan="2" style="width:120px; background-color: #F7E7DC;">Chi hộ</th>
                                </tr>
                                <tr>
                                    <th style="width:120px; background-color: #88D66C;">Tổng chi phí</th>
                                    <th style="width:120px; background-color: #88D66C;">Tổng doanh thu</th>
                                    <th style="width:120px; background-color: #88D66C;">Lợi nhuận</th>

                                    <th style="width:120px; background-color: #FFD18E;">Chi phí cố định</th>
                                    <th style="width:120px; background-color: #FFD18E;">Lương chuyến</th>
                                    <th style="width:120px; background-color: #FFD18E;">Chi phí</th>
                                    <th style="width:120px; background-color: #FFD18E;">VETC</th>
                                    <th style="width:120px; background-color: #FFD18E;">Chi phí dầu</th>

                                    <th style="width:120px; background-color: #9CDBA6;">Phí dịch vụ</th>
                                    <th style="width:120px; background-color: #9CDBA6;">Cước vận chuyển</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="left">{{ item.fullName }}</td>
                                    <td class="center">{{ item.phone }}</td>
                                    <td class="center"><span style="font-weight: bold;">{{ item.numberJob }}</span></td>
                                    <td class="right">
                                        <span style="color: red; font-weight: bold;">{{ formatNumber(item.totalExpense) }}</span>
                                    </td>
                                    <td class="right">
                                        <span style="color: #059212; font-weight: bold;">{{ formatNumber(item.totalRevenue) }}</span>
                                    </td>
                                    <td class="right">
                                        <span v-if="item.totalProfit > 0" style="color: #3572EF; font-weight: bold;">{{ formatNumber(item.totalProfit) }}</span>
                                        <span v-else style="color: red; font-weight: bold;">{{ formatNumber(item.totalProfit) }}</span>
                                    </td>
                                    <td class="right">{{ formatNumber(item.fixedCost) }}</td>
                                    <td class="right">{{ formatNumber(item.paySalary) }}</td>
                                    <td class="right">{{ formatNumber(item.payFee) }}</td>
                                    <td class="right">{{ formatNumber(item.payVetc) }}</td>
                                    <td class="right">{{ formatNumber(item.fuel) }}</td>
                                    <td class="right">{{ formatNumber(item.payService) }}</td>
                                    <td class="right">{{ formatNumber(item.transportCost) }}</td>
                                    <td class="right">{{ formatNumber(item.payOnBehalf) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                 <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <driverList ref="driverList" @close="closeDriver"/>
    </div>
</template>
<script>
    import reportDriverService from '../../../api/reportDriverService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import driverList from '../../../components/popup/_DriverList.vue';
    import driverService from '../../../api/driverService';

    export default {
        components: {
            driverList,
        },
        metaInfo: {
            title: 'Báo cáo sản lượng lái xe'
        },
        data() {
            return {
                loadding: false,
                search: { pageIndex: 1, pageSize: common.pageSize, fromDate: this.getRangeDate(-7), toDate: common.dateNow, driverId: 0 },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                driverName: '',
                drivers: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.filter();
        },
        mounted() {
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search = { pageIndex: 1, pageSize: common.pageSize, fromDate: this.getRangeDate(-7), toDate: common.dateNow, orderId: 0 };
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/report-driver?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                reportDriverService.getReportSummaryDriver(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            //Driver
            closeDriver(item){
                this.driverName = item.fullName;
                this.search.driverId = item.id;
                this.$refs.driverList.close();
            },

            getDriverSelected(val){
                this.search.driverId = val.id;
                this.driverName = val.fullName;
            },

            openDriver(){
                this.$refs.driverList.open();
            },

            getDrivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                driverService.getDrivers(search).then((response) => {
                    if(response.statusCode == 200){
                        this.drivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            driverName: function (val) { 
                if(val == ''){
                    this.search.driverId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.driverId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.fromDate': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.toDate': function () {
                this.search.pageIndex = 1;
                this.filter();
            }
        }
    }

</script>
